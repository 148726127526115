import * as React from 'react'
import Nav from './nav';
import './layout.scss';

const Layout = ({ children }) => {
  return (<div>
    <Nav/>

    <main>{children}</main>
  </div>);
};

export default Layout;
