import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faPhoneAlt, faKey, faPen } from '@fortawesome/free-solid-svg-icons'
import check from '../images/check.gif';

import './contactForm.scss';

const Success = () => {
  const { t } = useTranslation();

  return (<div className="Success">
    <img alt="Succesful submit" src={check} />
    <p>{t('contact.success')}</p>
  </div>);
};

const ContactForm = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const formSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target)

    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString()
    }).then(resp => resp.ok ? setSuccess(true) : setError(true)).catch(() => setError(true));
  };

  return (<section id="contact" className="ContactForm">
    <h2>{t('contact.title')}</h2>

    { success ? <Success/> : <form name="contact" onSubmit={formSubmit} data-netlify="true" method="post">
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />

      <div className="InputWrapper">
        <FontAwesomeIcon icon={faUser}/>
        <input name="name" required type="text" placeholder={t('contact.name')} />
      </div>
      <div className="InputWrapper">
        <FontAwesomeIcon icon={faEnvelope}/>
        <input name="email" required type="email" placeholder={t('contact.email')} />
      </div>
      <div className="InputWrapper">
        <FontAwesomeIcon icon={faPhoneAlt}/>
        <input name="phone" type="text" placeholder={t('contact.phone')} />
      </div>
      <div className="InputWrapper">
        <FontAwesomeIcon icon={faKey}/>
        <input name="subject" type="text" placeholder={t('contact.subject')} />
      </div>
      <div className="InputWrapper">
        <FontAwesomeIcon icon={faPen}/>
        <textarea name="message" required placeholder={t('contact.message')}></textarea>
      </div>
      { error ? <p>{t('contact.error')}</p> : null }
      <input name="submit" type="submit" value={t('contact.send')}/>
    </form> }
  </section>);
};

export default ContactForm;
