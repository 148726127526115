import * as React from 'react'
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import logo from '../images/logo_white.png';
import MicrosoftPartner from '../images/microsoft-partner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import cms from '../cms/company.yml';

import './footer.scss';

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (<footer className="Footer">
    <div className="Left">
      <img alt="Infocus Logo" src={logo} />
      <ul>
        <li><AnchorLink offset="75" href="#hero">{t('nav.home')}</AnchorLink></li>
        <li><AnchorLink offset="75" href="#about">{t('nav.about')}</AnchorLink></li>
        <li><AnchorLink offset="75" href="#services">{t('nav.services')}</AnchorLink></li>
        <li><AnchorLink offset="75" href="#clients">{t('nav.clients')}</AnchorLink></li>
        <li><AnchorLink offset="75" href="#technologies">{t('nav.technologies')}</AnchorLink></li>
        <li><AnchorLink offset="75" href="#team">{t('nav.team')}</AnchorLink></li>
        { i18n.language === 'es' ? <li><Link to="/news">{t('nav.news')}</Link></li> : null }
      </ul>
      <p>Infocus © {new Date().getFullYear()}</p>
    </div>

    <address className="Center">
      <a rel="noopener noreferrer" target="_blank" href={cms.google_maps}>
        <FontAwesomeIcon icon={faMapMarkerAlt}/>
        <div className="Address">{cms.address1}<br/>{cms.address2}</div>
      </a>
      <a href={`callto:${cms.phone}`}>
        <FontAwesomeIcon icon={faPhoneAlt}/>{cms.phone}
      </a>
      <a href={`mailto:${cms.email}`}>
        <FontAwesomeIcon icon={faEnvelope}/>{cms[`email_${i18n.language}`]}
      </a>
    </address>

    <div className="Right">
      <h3>{cms[`about_title_${i18n.language}`]}</h3>
      <p>{cms[`about_content_${i18n.language}`]}</p>

      <div className="Icons">
        <a rel="noopener noreferrer" aria-label="Twitter" target="_blank" href={cms.twitter}><FontAwesomeIcon icon={faTwitter}/></a>
        <a rel="noopener noreferrer" aria-label="Linkedin" target="_blank" href={cms.linkedin}><FontAwesomeIcon icon={faLinkedinIn}/></a>
        <a rel="noopener noreferrer" aria-label="Facebook" target="_blank" href={cms.facebook}><FontAwesomeIcon icon={faFacebookF}/></a>
        <img alt="Microsoft partner" src={MicrosoftPartner}></img>
      </div>

      <a className="Credits" rel="noopener noreferrer" target="_blank" href="http://maximobelen.com">Powered by @mbfassnacht</a>
    </div>
  </footer>);
};

export default Footer;
