import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { slide as BurgerMenu } from 'react-burger-menu';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import './nav.scss';

export default function Nav() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t, language, originalPath } = useI18next();
  const closeMenu = () => setMenuOpen(false);

  return (
    <nav className='NavBar'>
      <Link to="/" className="logo"><StaticImage src="../images/logo.png" alt="Infocus Logo" height={50} /></Link>

      <ul className='NavBar__list--desktop'>
        { originalPath === '/' ? <>
            <li><AnchorLink offset="75" href="#hero">{t('nav.home')}</AnchorLink></li>
            <li><AnchorLink offset="75" href="#about">{t('nav.about')}</AnchorLink></li>
            <li><AnchorLink offset="75" href="#services">{t('nav.services')}</AnchorLink></li>
            <li><AnchorLink offset="75" href="#clients">{t('nav.clients')}</AnchorLink></li>
            <li><AnchorLink offset="75" href="#team">{t('nav.team')}</AnchorLink></li>
            { language === 'es' ? <li><Link to="/news">{t('nav.news')}</Link></li> : null }
            <li><AnchorLink offset="75" href="#contact">{t('nav.contact')}</AnchorLink></li>
          </> : <>
            <li><Link to="/">{t('nav.home')}</Link></li>
          </>
        }

        <li className="LanguageList">
          <Link to={originalPath} language="es">|ES|</Link>
          <Link to={originalPath} language="en">|EN|</Link>
        </li>
      </ul>

      <div className="NavBar__list--mobile">
        <BurgerMenu isOpen={menuOpen} onStateChange={state => setMenuOpen(state.isOpen)} width={'100%'} right>
          { originalPath === '/' ? <>
              <li><AnchorLink offset="75" onClick={closeMenu} href="#hero">{t('nav.home')}</AnchorLink></li>
              <li><AnchorLink offset="75" onClick={closeMenu} href="#about">{t('nav.about')}</AnchorLink></li>
              <li><AnchorLink offset="75" onClick={closeMenu} href="#services">{t('nav.services')}</AnchorLink></li>
              <li><AnchorLink offset="75" onClick={closeMenu} href="#clients">{t('nav.clients')}</AnchorLink></li>
              <li><AnchorLink offset="75" onClick={closeMenu} href="#team">{t('nav.team')}</AnchorLink></li>
              { language === 'es' ? <li><Link onClick={closeMenu} to="/news">{t('nav.news')}</Link></li> : null }
              <li><AnchorLink offset="75" onClick={closeMenu} href="#contact">{t('nav.contact')}</AnchorLink></li>
            </> : <>
              <li><Link to="/">{t('nav.home')}</Link></li>
            </>
          }

          <li className="LanguageList">
            <Link to={originalPath} language="es">|ES|</Link>
            <Link to={originalPath} language="en">|EN|</Link>
          </li>
        </BurgerMenu>
      </div>
    </nav>
  );
}
